<template>
  <div class="popup-container box-metadata" v-show="visibleLayer && positionPin" :style="{ top: positionPin.y + 'px', left: positionPin.x + 'px', fontFamily: layer.popup.family, background: gradient, transform: clickingPin ? 'none' : '' }" ref="popupBorder">
    <div>
      <div class="popup-detail d-flex" v-show="(popupImage && popupImage.length !== 0 && currentValue !== null) || (!popupImage && currentValue !== null) || (popupImage && popupImage.length === 0 && currentValue !== null) || (popupImage && popupImage.length === 0 && !flagFilter && currentValue !== null)" style="min-width: 0px; padding: 7px; overflow: hidden; transition: 0.2s">
        <div class="content-information" style="width: 300px">
          <div class="popup-header" :style="{ fontSize: layer.popup.headerSize + 'px', color: layer.popup.headerColor }">
            <div style="white-space: nowrap; overflow: hidden; text-overflow: ellipsis">
              <!-- {{ layer && layer.dataTimeseries ? location.feature.properties[idMultiSelected] : layer && !layer.dataTimeseries && layer.itemMultiSelected && clicking && clicking.itemMultiSelected ? clicking.itemMultiSelected : '' }} - <span style="text-transform: none !important">{{ layer.name }}</span> -->
              {{ nameItem }}
            </div>
            <div class="d-flex" style="gap: 2px">
              <span @click="clickingPin = !clickingPin" :title="clickingPin ? $t('pin_on') : $t('pin_off')">
                <svg v-if="!clickingPin" style="width: 16px; height: 16px; cursor: pointer" viewBox="0 0 24 24">
                  <path fill="currentColor" d="M16,12V4H17V2H7V4H8V12L6,14V16H11.2V22H12.8V16H18V14L16,12M8.8,14L10,12.8V4H14V12.8L15.2,14H8.8Z" />
                </svg>
                <svg v-else style="width: 16px; height: 16px; cursor: pointer" viewBox="0 0 24 24">
                  <path fill="currentColor" d="M8,6.2V4H7V2H17V4H16V12L18,14V16H17.8L14,12.2V4H10V8.2L8,6.2M20,20.7L18.7,22L12.8,16.1V22H11.2V16H6V14L8,12V11.3L2,5.3L3.3,4L20,20.7M8.8,14H10.6L9.7,13.1L8.8,14Z" />
                </svg>
              </span>
              <div :title="$t('add_image')" @click="toggleImage">
                <feather-icon size="16" icon="ImageIcon" class="cursor-pointer" />
              </div>
              <div v-if="layer.popup.multiShow" @click="closePopup(clicking.layerId, clicking.clickId, clicking.clickUid, layer.type)">
                <feather-icon size="16" icon="XIcon" class="cursor-pointer" />
              </div>
              <div v-show="((popupImage && popupImage.length !== 0) || (popupImage && popupImage.length === 0 && flagFilter)) && ((popupImage && popupImage.length !== 0 && currentValue !== null) || (!popupImage && currentValue !== null) || (popupImage && popupImage.length === 0 && currentValue !== null) || (popupImage && popupImage.length === 0 && !flagFilter && currentValue !== null))" v-if="!showDetailPopup && dataFilterShow.length !== 0" :title="$t('expand_metadata')" @click="showDetailPopup = !showDetailPopup">
                <feather-icon size="16" icon="ChevronsDownIcon" class="cursor-pointer icon-show-detail" />
              </div>
              <div v-show="((popupImage && popupImage.length !== 0) || (popupImage && popupImage.length === 0 && flagFilter)) && ((popupImage && popupImage.length !== 0 && currentValue !== null) || (!popupImage && currentValue !== null) || (popupImage && popupImage.length === 0 && currentValue !== null) || (popupImage && popupImage.length === 0 && !flagFilter && currentValue !== null))" v-if="showDetailPopup && dataFilterShow.length !== 0" :title="$t('collapse_metadata')" @click="showDetailPopup = !showDetailPopup">
                <feather-icon size="16" icon="ChevronsUpIcon" class="cursor-pointer icon-show-detail" />
              </div>
            </div>
          </div>
          <div class="popup-content mt-1" :style="{ fontSize: layer.popup.contentSize + 'px', color: layer.popup.contentColor }">
            <div class="popup-column information">
              <div class="popup-value-time">
                <div class="popup-info">
                  <span>{{ $t('value_popup') }}</span>
                  <span>{{ !isOutside ? currentValueFormat : 0 }}</span>
                </div>
                <div class="popup-value-time-item" :style="{ backgroundColor: `${layer.popup.contentColor}80`, backgroundImage: `linear-gradient(to right, ${layer.popup.contentColor} 0%, ${layer.popup.contentColor} ${valuePercent}%, transparent ${valuePercent}%, transparent 100%)`, marginBottom: `${4 * layer.popup.contentSize - 18 - 2}px` }">
                  <div class="cursor" :style="{ borderBottomColor: layer.popup.contentColor, left: `${valuePercent}%` }"></div>
                </div>
                <div class="popup-info">
                  <span>{{ $t('time_popup') }}</span>
                  <span>{{ !isOutside ? currentTime : '' }}</span>
                </div>
                <div class="popup-value-time-item" :style="{ backgroundColor: `${layer.popup.contentColor}80`, backgroundImage: `linear-gradient(to right, ${layer.popup.contentColor} 0%, ${layer.popup.contentColor} ${timePercent}%, transparent ${timePercent}%, transparent 100%)` }">
                  <div class="cursor" :style="{ borderBottomColor: layer.popup.contentColor, left: `${timePercent}%` }"></div>
                </div>
              </div>
              <!-- <div class="popup-info">
                <span>{{ $t('value') }}</span>
                <span>{{ !isOutside ? currentValueFormat : 0 }}</span>
              </div>
              <div class="popup-info">
                <span>{{ $t('time') }}</span>
                <span>{{ !isOutside ? currentTime : '' }}</span>
              </div> -->
              <div class="popup-info">
                <span>{{ $t('min') }}</span>
                <span>{{ !isOutside ? statistic.min : 0 }}</span>
              </div>
              <div class="popup-info">
                <span>Q1</span>
                <span>{{ !isOutside ? statistic.first : 0 }}</span>
              </div>
              <div class="popup-info">
                <span>{{ $t('median') }}</span>
                <span>{{ !isOutside ? statistic.median : 0 }}</span>
              </div>
              <div class="popup-info">
                <span>{{ $t('mean') }}</span>
                <span>{{ !isOutside ? statistic.mean : 0 }}</span>
              </div>
              <div class="popup-info">
                <span>Q3</span>
                <span>{{ !isOutside ? statistic.third : 0 }}</span>
              </div>
              <div class="popup-info m-0">
                <span>{{ $t('max') }}</span>
                <span>{{ !isOutside ? statistic.max : 0 }}</span>
              </div>
            </div>
            <div class="popup-column chart">
              <div style="max-width: 300px" v-show="showTimeseriesChart" ref="timeseriesChart" class="timeseries-chart"></div>
              <div class="timeseries-chart-tick-date" v-if="dates && dates.length && showTimeseriesChart && values && values.length">
                <div class="d-flex flex-column timeseries-chart-tick-date-start">
                  <div>
                    {{ formatDateHMS(dates[0]) }}
                  </div>
                  <div>
                    {{ formatDateYMD(dates[0]) }}
                  </div>
                </div>
                <div class="d-flex flex-column timeseries-chart-tick-date-end">
                  <div>
                    {{ formatDateHMS(dates[dates.length - 1]) }}
                  </div>
                  <div>
                    {{ formatDateYMD(dates[dates.length - 1]) }}
                  </div>
                </div>
              </div>
              <div style="max-width: 300px" v-show="showBoxplotChart" ref="boxplotChart" class="boxplot-chart"></div>
              <div style="max-width: 300px" v-show="showHistogramChart" ref="histogramChart" class="histogram-chart"></div>
            </div>
          </div>
          <div class="popup-content" :style="{ fontSize: layer.popup.contentSize + 'px', color: layer.popup.contentColor }">
            <div class="popup-column information information-chart-pp">
              <div class="popup-info m-0" style="line-height: unset">
                <span>{{ $t('chart') }}</span>
                <span v-show="!isOutside">{{ valueHoverChart.value }}</span>
              </div>
            </div>
            <div class="popup-column chart">
              <div class="popup-info m-0" style="line-height: unset">
                <span v-show="!isOutside" style="font-weight: unset">{{ valueHoverChart.time }}</span>
                <span v-show="!isOutside">{{ valueHoverChart.item }}</span>
              </div>
            </div>
          </div>
        </div>

        <div class="content-image">
          <div style="min-width: 0px; overflow: hidden; transition: 0.2s" :style="{ fontSize: layer.popup.headerSize + 'px', color: layer.popup.headerColor, height: '218px' }">
            <div style="bottom: 7px; position: absolute; z-index: 1000">
              <div v-if="popupImage && popupImage.length !== 0 && flagFilter" style="width: 240px; padding: 0px 15px; justify-content: space-between" class="d-flex">
                <div class="d-flex" v-if="popupImage" :style="{ fontSize: layer.popup.headerSize + 'px', color: layer.popup.contentColor ? layer.popup.contentColor : 'gray' }">
                  <b-form-input style="background: transparent; height: 20px; border: 0px; font-size: 14px; padding: 0px; width: 15px; box-shadow: none !important" min="0" :max="popupImage.length" type="number" :value="currentSlide + 1" @click.stop @blur="selectPageImage($event.target.value)" :style="{ color: layer.popup.contentColor ? layer.popup.contentColor : 'gray' }" />
                  <span :style="{ fontSize: layer.popup.headerSize + 'px', color: layer.popup.contentColor ? layer.popup.contentColor : 'gray' }"> / {{ popupImage.length }}</span>
                </div>
                <div v-if="popupImage" :style="{ fontSize: layer.popup.headerSize + 'px', color: layer.popup.contentColor ? layer.popup.contentColor : 'gray' }">{{ $t('total') }} : {{ totalImagePopup }}</div>
              </div>
            </div>

            <div style="position: relative; width: 240px" v-if="(popupImage && popupImage.length !== 0) || (popupImage && popupImage.length === 0 && flagFilter)">
              <b-carousel v-model="currentSlide" :interval="0" img-width="924" img-height="380" controls indicators>
                <b-carousel-slide class="slide-image-metadata" v-for="(imgSrc, index) in popupImage" :key="index" data-interval="false">
                  <div class="icon-control-image upload-image" :title="$t('replace_files')" @click="toggleReplaceImage(imgSrc.id)">
                    <feather-icon :style="{ color: layer.popup.contentColor + '!important' }" icon="RefreshCcwIcon" size="16" class="cursor-pointer text-light" />
                  </div>
                  <div class="btn-flat-danger icon-control-image" :title="$t('delete')" @click="deleteImage(imgSrc.id)">
                    <feather-icon icon="Trash2Icon" size="16" class="cursor-pointer text-danger" />
                  </div>
                  <template v-slot:img>
                    <a :id="`sk-${imgSrc.id}`" :href="getImageSourceById(imgSrc.id)" style="display: none" :data-description="showDesMetadata(imgSrc.metadata)" class="glightbox" data-desc-position="right" data-type="image" data-effect="fade" data-zoomable="true" data-draggable="true" data-dragToleranceY="0"></a>
                    <div class="cursor-pointer" @click="showLightBox(imgSrc.id)">
                      <div class="viewport" v-if="currentSlide === index">
                        <img class="d-block" :src="getImageSourceById(imgSrc.id)" alt="image" />
                      </div>
                    </div>
                  </template>
                </b-carousel-slide>
              </b-carousel>
            </div>
          </div>
        </div>
      </div>

      <div class="setting-metadata" style="transition: 0.2s; overflow: hidden" :style="{ 'max-height': setHeightDetailPopup(), padding: setpaddingDetailPopup() }">
        <div v-show="popupImage && popupImage.length === 0 && flagFilter" style="height: 100%; padding-top: 125px; padding-bottom: 145px" :style="{ fontSize: layer.popup.headerSize + 'px', color: layer.popup.headerColor }">
          <div style="text-align: center">{{ $t('empty_image') }}</div>
          <div class="mt-1" style="text-align: center">
            <b-button :style="{ fontSize: layer.popup.headerSize + 'px', color: layer.popup.headerColor }" @click="clearFilter">{{ $t('clear_filter') }}</b-button>
          </div>
        </div>
        <div v-if="popupImage && popupImage.length !== 0">
          <div v-if="dataFilter.length !== 0" class="d-flex">
            <div v-for="(filter, index) in dataFilterShow" :key="index">
              <FilterMetadata @getDatafilter="getDataFilter" :value="selectedFilter" :options="filter" :name="filter.text" :mode="'border'" />
            </div>
          </div>
          <div class="table-metadata custom-scrollbar m-auto" :style="{ fontSize: layer.popup.contentSize + 'px', color: layer.popup.contentColor }">
            <table class="table b-table" :style="{ fontSize: layer.popup.contentSize + 'px', color: layer.popup.contentColor, background: 'transparent' }">
              <thead :style="{ fontSize: layer.popup.contentSize + 'px', color: layer.popup.contentColor, 'border-color': layer.popup.contentColor + '!important' }">
                <tr role="row" class="">
                  <th style="border: none !important" v-for="(title, index) in popupImage[currentSlide].metadata[0]" :key="index" :style="{ fontSize: layer.popup.contentSize + 'px', color: layer.popup.contentColor, 'border-color': layer.popup.contentColor + '!important' }">
                    <div v-if="title.toUpperCase() === 'CLASSIFICATION'">{{ $t('classification') }}</div>
                    <div v-else-if="title.toUpperCase() === 'METADATA'">{{ $t('metadata') }}</div>
                    <div v-else-if="title.toUpperCase() === 'VALUE'">{{ $t('value') }}</div>
                    <div v-else>{{ title }}</div>
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(line, index) in popupImage[currentSlide].metadata" :key="index">
                  <td style="vertical-align: top; line-height: normal" v-for="(title, indexLine) in line" :key="indexLine" :style="{ 'border-top': indexLine < 1 && title === '' ? 'none !important' : '1px solid', fontSize: layer.popup.contentSize + 'px', color: layer.popup.contentColor, 'border-color': layer.popup.contentColor + '!important' }">
                    <div v-if="index !== 0">
                      <div v-if="indexLine < 2">{{ title }}</div>

                      <div v-else-if="dataSelectMetadata[line[1]] && dataSelectMetadata[line[1]].option.length !== 0">
                        <SelectMetadataComponent :class="{ 'select-one': dataSelectMetadata[line[1]].typeFilter === 'single' }" :mode="'border'" @selectValue="(valueSelect) => updateMetadata(index, indexLine, valueSelect, popupImage[currentSlide].metadata, popupImage[currentSlide].id, dataSelectMetadata[line[1]].typeFilter)" :value="title.replaceAll('、', ',').split(',')" :options="dataSelectMetadata[line[1]].option" />
                      </div>
                      <div v-else class="d-flex">
                        <b-form-input class="input-indexpage" @change="updateMetadata(index, indexLine, $event, popupImage[currentSlide].metadata, popupImage[currentSlide].id)" style="padding: 0px; height: 18px; background: transparent; border: 0px" :style="{ fontSize: layer.popup.contentSize + 'px', color: layer.popup.contentColor }" :value="title" />
                        <feather-icon v-if="line[1] === '測定日時'" size="16" icon="CalendarIcon" style="align-self: center" :style="{ fontSize: layer.popup.contentSize + 'px', color: layer.popup.contentColor }" />
                        <feather-icon v-if="line[1] === '緯度経度'" size="16" icon="MapPinIcon" style="align-self: center" :style="{ fontSize: layer.popup.contentSize + 'px', color: layer.popup.contentColor }" />
                      </div>
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
    <input type="file" ref="replaceImage" style="display: none" @change="replaceImage" accept=".png, .jpeg, .jpg" />
    <input type="file" ref="image" multiple style="display: none" @change="uploadImagesEvt" accept=".png, .jpeg, .jpg, .zip" />
  </div>
</template>

<script>
const _ = require('lodash')
const dayjs = require('dayjs')
const relativeTime = require('dayjs/plugin/relativeTime')
import interact from 'interactjs'
import Plotly from 'plotly.js-dist'
import GLightbox from 'glightbox'
import i18n from '@/libs/i18n'
import MathMixin from '@/mixins/MathMixin'
import RadioGroup from '../../../common/RadioGroup.vue'
import DynamicAnimate from '../../../common/DynamicAnimate.vue'
import SelectMetadataComponent from './SelectMetadataComponent.vue'
import FilterMetadata from './FilterMetadataComponent.vue'
import ImageMixin from '@/mixins/ImageMixin'
import ToastificationContent from '@/@core/components/toastification/ToastificationContent.vue'
import 'glightbox/dist/css/glightbox.css'
import { BCarousel, BCarouselSlide, VBTooltip } from 'bootstrap-vue'
import { BTable } from 'bootstrap-vue'
import moment from 'moment'
import 'moment/locale/ja'
import { locale } from '@/scripts/plotly/locale.js'
import { METADATA_POPUP } from '@/constants/metadata.js'
dayjs.extend(relativeTime)

export default {
  props: ['map', 'moveCount', 'layer', 'leafletLayer', 'clicking', 'mapIndex'],
  components: { BCarousel, BCarouselSlide, RadioGroup, BTable, DynamicAnimate, FilterMetadata, SelectMetadataComponent },
  directives: { 'b-tooltip': VBTooltip },
  mixins: [MathMixin, ImageMixin, ToastificationContent],
  mounted() {
    'undefined' == typeof Plotly ? ((window.PlotlyLocales = window.PlotlyLocales || []), window.PlotlyLocales.push(locale)) : Plotly.register(locale)
    this.createLightbox()
    this.dragPopup()

    // this.popup.draggable(!this.clicking.pin);
    let mapEle = document.querySelector(`.map-container .map-list .app-leaflet-map-${this.mapIndex}`)
    if (mapEle) {
      new ResizeObserver(() => {
        if (this.popup && !this.clickingPin) {
          this.popup.reflow({ name: 'drag', axis: 'xy' })
        }
      }).observe(mapEle)
    }
    try {
      if (!this.clicking.pin) {
        if (this.$refs.popupBorder && this.clicking && this.clicking.position) {
          this.$refs.popupBorder.style.transform = `translate(${this.clicking.position.x}px, ${this.clicking.position.y}px)`
          this.$refs.popupBorder.setAttribute('data-x', this.clicking.position.x)
          this.$refs.popupBorder.setAttribute('data-y', this.clicking.position.y)
        }
      } else {
        this.$refs.popupBorder.style.transform = `none`
        this.$refs.popupBorder.removeAttribute('data-x')
        this.$refs.popupBorder.removeAttribute('data-y')
      }
    } catch {}
  },
  updated() {
    try {
      this.popup.draggable(!this.clickingPin ? true : false)
      if (!this.clicking.pin) {
        if (this.$refs.popupBorder) {
          this.$refs.popupBorder.style.transform = `translate(${this.clicking.position.x}px, ${this.clicking.position.y}px)`
          this.$refs.popupBorder.setAttribute('data-x', this.clicking.position.x)
          this.$refs.popupBorder.setAttribute('data-y', this.clicking.position.y)
        }
        this.popup.reflow({ name: 'drag', axis: 'xy' })
      } else {
        this.$refs.popupBorder.style.transform = `none`
        this.$refs.popupBorder.removeAttribute('data-x')
        this.$refs.popupBorder.removeAttribute('data-y')
      }
    } catch {}
  },
  data() {
    return {
      filterNatural: [],
      filterFuntion: [],
      filterPlance: [],
      filterProcess: [],
      filterState: [],
      filterMeasurement: [],
      selectedFilter: {},
      flagFilter: false,
      dates: [],
      values: [],
      lightbox: null,
      metadata: METADATA_POPUP,
      showDetailPopup: false,
      currentSlide: 0,
      flagCurrentSlide: 0,
      listFilter: null,
      valueHoverChart: { time: '', value: '', item: '' },
      idImageReplace: null,
      showTimeseriesChart: true,
      showBoxplotChart: true,
      showHistogramChart: true,
    }
  },
  computed: {
    nameItem() {
      //   return this.layer.type !== 'multi' || this.layer.dataTimeseries ? this.location.feature.properties[this.idMultiSelected] : this.hovering && this.hovering.idMultiSelected ? this.hovering.idMultiSelected : null;
      let item = ``
      try {
        item = this.location.feature.properties[this.idMultiSelected]
      } catch {}
      try {
        if (!item || item == '') {
          item = this.clicking.itemMultiSelected
        }
      } catch {}
      return `${item} - ${this.layer.name}`
    },
    isOutside() {
      let isOutside = true
      try {
        if (!this.layer.dataTimeseries) {
          return true
        }
        const dataTimeseries = this.$db[this.layer.dataTimeseries]
        if (dataTimeseries.dates) this.dates = dataTimeseries.dates
        if (dataTimeseries && dataTimeseries.dates && dataTimeseries.dates.length) {
          isOutside = !(dataTimeseries.dates[0] <= this.date[0] && this.date[0] <= dataTimeseries.dates[dataTimeseries.dates.length - 1])
        }
      } catch {}
      if (isOutside) {
        this.showBoxplotChart = false
        this.showHistogramChart = false
        this.showTimeseriesChart = false
      } else {
        this.showBoxplotChart = true
        this.showHistogramChart = true
        this.showTimeseriesChart = true
      }
      return isOutside
    },
    locale() {
      return i18n.locale
    },

    date() {
      return this.$store.state.map.date
    },
    location() {
      return this.leafletLayer.getLayers().find((l) => {
        try {
          if (l.feature.properties[this.idMultiSelected]) {
            if (this.layer && this.layer.type && this.layer.type == 'point') {
              return l.feature.properties[this.idMultiSelected] == this.clicking.clickId
            } else {
              if (this.clicking) {
                if (this.clicking.clickUid && this.clicking.latlng) {
                  return l.feature.properties[this.idMultiSelected] == this.clicking.clickId && l.feature.properties.UID == this.clicking.clickUid && JSON.stringify(l.latlng) == JSON.stringify(this.clicking.latlng)
                } else if (!this.clicking.clickUid && this.clicking.latlng) {
                  return l.feature.properties[this.idMultiSelected] == this.clicking.clickId && JSON.stringify(l.latlng) == JSON.stringify(this.clicking.latlng)
                } else if (this.clicking.clickUid && !this.clicking.latlng) {
                  return l.feature.properties[this.idMultiSelected] == this.clicking.clickId && l.feature.properties.UID == this.clicking.clickUid
                } else {
                  return l.feature.properties[this.idMultiSelected] == this.clicking.clickId
                }
              }
            }
          } else if (l.noneId && ((this.clicking.clickId && `${l.noneId}` == `${this.clicking.clickId}`) || (!this.clicking.clickId && this.clicking.layerId && this.clicking.latlng && `${l.noneId}` == `${this.clicking.layerId}-${this.clicking.latlng[0]},${this.clicking.latlng[1]}`))) {
            return true
          }
        } catch {}
        return false
      })
    },
    layerOrigin() {
      try {
        const layers = this.$store.state.layer.layers
        if (layers) {
          for (let i = 0; i < layers.length; i++) {
            if (layers[i].id == this.clicking.layerId) {
              return layers[i]
            }
          }
        }
      } catch {}
      return
    },
    positionPin() {
      const self = this
      try {
        if (this.layerOrigin) {
          if (this.layerOrigin.type == 'point') {
            let useMarkerCluster
            try {
              useMarkerCluster = this.layerOrigin && this.layerOrigin.markerCluster && this.layerOrigin.markerCluster.using
            } catch {}
            if (this.clicking.source && useMarkerCluster) {
              if (this.clicking.pin) {
                this.moveCount
              }

              if (this.clicking.pin || !this.clicking.position || (this.clicking.position && !this.clicking.position.x && !this.clicking.position.y && !this.clicking.position.top && !this.clicking.position.left)) {
                this.moveCount

                let allCluster
                allCluster = this.leafletLayer._parentGroup._featureGroup._layers
                if (allCluster && Object.keys(allCluster).length) {
                  for (let key in allCluster) {
                    try {
                      if (allCluster[key]._childCount) {
                        let allclusterChild = allCluster[key].getAllChildMarkers()
                        for (let k = 0; k < allclusterChild.length; k++) {
                          if (allclusterChild[k].feature.properties[this.idMultiSelected] == self.clicking.clickId) {
                            if (allCluster[key].isSpiderfied) {
                              return self.map.latLngToContainerPoint(allclusterChild[k].getLatLng())
                            } else {
                              return self.map.latLngToContainerPoint(allCluster[key].getLatLng())
                            }
                            break
                          }
                        }
                      } else {
                        if (allCluster[key].feature.properties[this.idMultiSelected] == self.clicking.clickId) {
                          return self.map.latLngToContainerPoint(allCluster[key].getLatLng())
                        }
                      }
                    } catch {}
                  }
                }
              } else {
                if (this.clicking.position && this.clicking.position) {
                  return {
                    y: this.clicking.position.top,
                    x: this.clicking.position.left,
                  }
                }
              }
            } else {
              if (this.clicking.pin) {
                this.moveCount
              } else {
                if (this.clicking.position && this.clicking.position.top) {
                  return {
                    y: this.clicking.position.top,
                    x: this.clicking.position.left,
                  }
                }
              }
              return this.map.latLngToContainerPoint(this.location.latlng)
            }
          } else {
            if (this.clicking.pin) {
              this.moveCount
            } else {
              if (this.clicking.position && this.clicking.position.top) {
                return {
                  y: this.clicking.position.top,
                  x: this.clicking.position.left,
                }
              }
            }
            return this.map.latLngToContainerPoint(this.location.latlng)
          }
        }
      } catch {}
      return {
        y: 0,
        x: 0,
      }
    },
    gradient() {
      let color1 = this.layer.popup.backgroundColorFrom
      let color2 = this.layer.popup.backgroundColorTo
      return 'linear-gradient(to bottom, ' + color1 + ', ' + color2 + ')'
    },
    popupImages() {
      let data = []
      try {
        if (this.layer.type == 'point') {
          data = this.$store.state.image.popupImages.filter((data) => data.layerId === this.clicking.layerId && data.locationItem === this.clicking.clickId)
        } else {
          data = this.$store.state.image.popupImages.filter((data) => data.layerId === this.clicking.layerId && data.locationItem === this.clicking.clickId && (this.clicking.latlng && data.latlngPos ? JSON.stringify(data.latlngPos) === JSON.stringify(this.clicking.latlng) : true))
        }
      } catch {}
      return data
    },
    listChangeListPopup() {
      return this.$store.state.image.popupImages
    },
    totalImagePopup() {
      return this.popupImages.length
    },
    popupImage() {
      this.flagFilter = false
      const imageData = this.filterImageMetadata(this.popupImages, this.selectedFilter)
      if (this.popupImages && this.popupImages.length !== 0) this.flagFilter = true
      else this.flagFilter = false
      this.createLightbox()
      return imageData
    },
    currentValue() {
      if (!this.values || !this.dates.length || this.date[0] < this.dates[0] || this.date[0] > this.dates[this.dates.length - 1]) return null
      let snapIndex = this.dates.reduce((res, date, index) => (date <= this.date[0] ? index : res), -1)
      return this.values[snapIndex]
    },
    currentValueFormat() {
      try {
        return this.autoFormatNumbers([this.currentValue], { addComma: true })[0]
      } catch {}
      return this.currentValue
    },
    valuePercent() {
      if (this.currentValue === null) return 0
      return ((this.currentValue - this.statistic.minDefault) / (this.statistic.maxDefault - this.statistic.minDefault)) * 100
    },
    calendarUnit() {
      return this.$store.state.settings.calendarUnit
    },
    currentTime() {
      const diffUnit = { yearly: 'years', monthly: 'months', daily: 'days', hourly: 'hours' }
      const diffUnitName = { yearly: this.$i18n.t('years'), monthly: this.$i18n.t('months'), daily: this.$i18n.t('days'), hourly: this.$i18n.t('hours') }
      let unitTime = null
      if (!this.dates.length || this.date[0] <= this.dates[0]) return null
      // return dayjs(this.date[0]).from(dayjs(this.dates[0]), true);

      let start = dayjs(this.dates[0])
      let end = dayjs(this.date[0])

      if (this.calendarUnit === 'auto') {
        for (const key in diffUnit) {
          let diff = end.diff(start, diffUnit[key])
          if (diff > 0 || key === 'hourly') {
            unitTime = diffUnitName[key]
            return diff + ' ' + unitTime
          }
        }
      }

      unitTime = diffUnitName[this.calendarUnit]
      return end.diff(start, diffUnit[this.calendarUnit])
    },
    timePercent() {
      if (!this.dates.length) return 0
      let min = new Date(this.dates[0]).getTime()
      let max = new Date(this.dates[this.dates.length - 1]).getTime()
      let current = new Date(this.date[0]).getTime()
      if (current < min) return 0
      if (current > max) return 100
      return ((current - min) / (max - min)) * 100
    },
    statistic() {
      let filtered = this.values ? this.values.filter((v) => v !== null) : []
      if (!filtered.length) return { min: 0, max: 0, mean: 0, median: 0, first: 0, third: 0 }
      let sorted = this.sortData(filtered)
      let minDefault = this.getMin(sorted)
      let maxDefault = this.getMax(sorted)
      let rounded = this.autoFormatNumbers([minDefault, maxDefault, this.getMean(sorted), this.getMedian(sorted), this.getFirstQuantile(sorted), this.getThirdQuantile(sorted)], { addComma: true })

      return { min: rounded[0], max: rounded[1], mean: rounded[2], median: rounded[3], first: rounded[4], third: rounded[5], minDefault, maxDefault }
    },
    itemChanged() {
      return this.layer.dataTimeseries + '_' + this.layer.item + '_' + this.clicking.clickId + '_' + this.layer.scale.value
    },
    liveMode() {
      if (!this.layer) return false
      let datasource = this.datasources.find((d) => d.id === this.layer.dataTimeseries)
      if (!datasource) return false
      return datasource.live
    },
    // refreshTime() {
    //   return this.$store.state.map.refreshTime;
    // },
    dataFilter() {
      return this.$store.state.settings.dataFilter
    },
    dataFilterShow() {
      try {
        this.selectedFilter = {}
        if (this.dataFilter && this.dataFilter[0].children.length !== 0) {
          let arrayObjFilter = this.dataFilter[0].children.filter((obj) => obj.visible === 'show')
          return arrayObjFilter
        }
      } catch {}
      return []
    },
    dataSelectMetadata() {
      let arrayShow = []
      let objSelect = {}
      if (this.dataFilter && this.dataFilter[0].children.length !== 0) {
        arrayShow = this.dataFilter[0].children.filter((obj) => obj.visible === 'show')
        for (let i = 0; i < arrayShow.length; i++) {
          let typeFilter = ''
          let result = []
          if (arrayShow[i] && arrayShow[i].children.length !== 0) {
            result = arrayShow[i].children
            typeFilter = arrayShow[i].typeFilter
          }
          objSelect[arrayShow[i].text] = { key: arrayShow[i].text, typeFilter: typeFilter, option: result }
        }
      }
      return objSelect
    },
    clickingMap() {
      return this.$store.state.map.clicking[this.mapIndex]
    },
    clickingPin: {
      get() {
        return this.clicking.pin
      },
      set(clickingPin) {
        let clickingTemp = this.clicking
        clickingTemp.pin = clickingPin
        this.popup.draggable(!clickingPin ? true : false)
        if (!clickingPin) {
          if (this.$refs.popupBorder && this.clicking && this.clicking.position) {
            if (clickingTemp.position) {
              delete clickingTemp.position
            } else {
              this.$refs.popupBorder.style.transform = `translate(${this.clicking.position.x}px, ${this.clicking.position.y}px)`
              this.$refs.popupBorder.setAttribute('data-x', this.clicking.position.x)
              this.$refs.popupBorder.setAttribute('data-y', this.clicking.position.y)
            }
          }
          this.popup.reflow({ name: 'drag', axis: 'xy' })
        } else {
          this.$refs.popupBorder.style.transform = `none`
          this.$refs.popupBorder.removeAttribute('data-x')
          this.$refs.popupBorder.removeAttribute('data-y')
        }

        this.$store.commit('map/UPDATE_CLICKING_PIN', {
          data: clickingTemp,
          mapIndex: this.mapIndex,
        })
      },
    },
    visibleLayer() {
      return this.layer.visible[this.mapIndex]
    },
    countLiveMode() {
      return this.$store.state.settings.countLiveMode
    },
    idMultiSelected() {
      let id = 'ID'
      try {
        id = this.layer.idMultiSelected ? this.layer.idMultiSelected : 'ID'
      } catch {}
      return id
    },
    localRange() {
      try {
        if (this.statistic && this.statistic.minDefault != null && this.statistic.maxDefault != null) {
          return [Number(this.statistic.minDefault), Number(this.statistic.maxDefault)]
        } else {
          if (!this.values || !this.values.length) {
            return
          } else {
            let sourceFilerNA = this.values.filter((bt) => !this.isNaValue(bt))
            let min = Math.min(...sourceFilerNA)
            let max = Math.max(...sourceFilerNA)
            return [Number(min), Number(max)]
          }
        }
      } catch {}
      return
    },
  },

  watch: {
    locale(locale) {
      if (locale === 'ja') {
        moment.locale('ja')
      } else {
        moment.locale('en')
      }
    },
    isOutside(isOutside) {
      if (isOutside) {
        this.showBoxplotChart = false
        this.showHistogramChart = false
        this.showTimeseriesChart = false
      } else {
        this.showBoxplotChart = true
        this.showHistogramChart = true
        this.showTimeseriesChart = true
      }
    },
    dataFilter() {
      this.listFilter = this.getlistCountFilter(this.popupImages)
    },
    listChangeListPopup: {
      deep: true,
      handler() {
        this.listFilter = this.getlistCountFilter(this.popupImages)
      },
    },
    locale() {
      this.updatePlotLiveData()
    },
    popupImage(newValue, oldValue) {
      try {
        if (newValue && newValue.length && oldValue && oldValue.length) {
          if (oldValue.length !== newValue.length) this.currentSlide = 0
        }
        if (document.querySelector('.popup-detail')) {
          if (!oldValue && newValue && newValue.length !== 0) {
            document.querySelector('.popup-detail').style.transition = 'none'
            setTimeout(() => {
              document.querySelector('.popup-detail').style.transition = '0.2s'
            }, 500)
          } else if (oldValue && oldValue.length === 0 && newValue && newValue.length !== 0) {
            document.querySelector('.popup-detail').style.transition = 'none'
            setTimeout(() => {
              document.querySelector('.popup-detail').style.transition = '0.2s'
            }, 500)
          } else if (!newValue || (newValue && newValue.length === 0 && !this.flagFilter)) {
            document.querySelector('.popup-detail').style.transition = 'none'
          } else {
            document.querySelector('.popup-detail').style.transition = '0.2s'
          }
        }
      } catch (error) {}
    },
    itemChanged: {
      immediate: true,
      handler() {
        this.plotChart()
      },
    },
    date: {
      immediate: true,
      handler() {
        this.updatePlotTimeIndex()
      },
    },
    // refreshTime() {
    //   this.updatePlotLiveData();
    // },
    countLiveMode() {
      this.updatePlotLiveData()
    },
  },
  methods: {
    formatDateHMS(date) {
      try {
        return moment(date).format('HH:mm:ss')
      } catch {}
      return date
    },
    formatDateYMD(date) {
      try {
        return moment(date).format('YYYY/MM/DD')
      } catch {}
      return date
    },
    formatDate(date) {
      try {
        if (this.locale === 'ja') {
          return moment(date).format('LL')
        }
        return moment(date).format('DD/MM/YYYY HH:mm:ss')
      } catch {}
      return date
    },
    toggleImage() {
      this.$refs.image.click()
    },
    toggleReplaceImage(id) {
      this.idImageReplace = id
      this.$refs.replaceImage.click(id)
    },
    dragPopup() {
      var self = this
      this.popup = interact(this.$refs.popupBorder)
      this.popup
        .draggable({
          inertia: false,
          allowFrom: '.popup-header',
          // ignoreFrom: '.action-container, .open-panel',
          modifiers: [
            interact.modifiers.restrictRect({
              restriction: document.querySelector(`.map-container .map-list .app-leaflet-map-${self.mapIndex}`),
              endOnly: true,
            }),
          ],
          listeners: {
            move(event) {
              let x = (parseFloat(event.target.dataset.x) || 0) + event.dx
              let y = (parseFloat(event.target.dataset.y) || 0) + event.dy
              event.target.style.transform = `translate(${x}px, ${y}px)`
              Object.assign(event.target.dataset, { x, y })
            },
          },
        })
        .on('dragend', (event) => {
          let x = (parseFloat(event.target.dataset.x) || 0) + event.dx
          let y = (parseFloat(event.target.dataset.y) || 0) + event.dy
          if (this.clicking && !this.clicking.pin) {
            let clickingUpdatePosition = _.merge(this.clicking, { position: { x, y, top: this.positionPin.y, left: this.positionPin.x } })
            this.$store.commit('map/UPDATE_CLICKING_POSITION_PIN', {
              data: clickingUpdatePosition,
              mapIndex: this.mapIndex,
            })
          }
        })
      this.popup.draggable(!this.clickingPin ? true : false)
    },

    getDataFilter() {
      let obj = _.cloneDeep(this.selectedFilter)
      this.selectedFilter = {}
      this.selectedFilter = obj
    },
    selectPageImage(indexPage) {
      if (indexPage > this.popupImage.length) {
        this.currentSlide = this.popupImage.length - 1
      } else if (indexPage < 0) {
        this.currentSlide = 0
      } else if (indexPage.length === 0) {
        this.currentSlide = 0
      } else this.currentSlide = indexPage - 1
    },
    setHeightDetailPopup() {
      if (this.showDetailPopup && this.popupImage && this.popupImage.length) return '345px'
      if (!this.showDetailPopup && this.popupImage && this.popupImage.length) return '0px'
      if (!this.popupImage) return '345px'
      if (this.popupImage && this.popupImage.length === 0 && this.flagFilter && this.showDetailPopup) return '345px'
      if (this.popupImage && this.popupImage.length === 0 && this.flagFilter && !this.showDetailPopup) return '0px'
      if (this.popupImage && this.popupImage.length === 0 && !this.flagFilter) return '345px'
    },
    setpaddingDetailPopup() {
      if (this.showDetailPopup && this.popupImage && this.popupImage.length) return '7px'
      if (!this.showDetailPopup && this.popupImage && this.popupImage.length) return '0px'
      if (!this.popupImage) return '7px'
      if (this.popupImage && this.popupImage.length === 0 && this.flagFilter && this.showDetailPopup) return '7px'
      if (this.popupImage && this.popupImage.length === 0 && this.flagFilter && !this.showDetailPopup) return '0px'
      if (this.popupImage && this.popupImage.length === 0 && !this.flagFilter) return '0px'
    },

    createLightbox() {
      setTimeout(() => {
        if (this.lightbox) {
          this.lightbox.destroy()
          this.lightbox = null
        }
        this.lightbox = GLightbox({
          dragToleranceY: 0,
        })
        this.lightbox.on('slide_changed', ({ current }) => {
          this.flagCurrentSlide = current.index
        })
        this.lightbox.on('close', () => {
          this.currentSlide = this.flagCurrentSlide
        })
      }, 0)
    },
    async plotChart() {
      try {
        this.listFilter = this.getlistCountFilter(this.popupImages)
        if (!this.layer.dataTimeseries || !this.layer.item) return

        // Query dates
        // Query values
        const locationItem = [this.location.feature.properties[this.idMultiSelected] + '-' + this.layer.item]
        let data = await this.selectAllByLocationsItems(this.layer.dataTimeseries, locationItem, this.layer.scale.value)
        this.values = data[locationItem]
        let layout = {
          paper_bgcolor: 'transparent',
          plot_bgcolor: 'transparent',
          showlegend: false,
          margin: { t: 0, r: 0, b: 0, l: 0, pad: 0 },
          xaxis: { automargin: true, color: this.layer.popup.contentColor, gridcolor: `${this.layer.popup.contentColor}80`, showgrid: false, visible: false },
          yaxis: { automargin: true, color: this.layer.popup.contentColor, gridcolor: `${this.layer.popup.contentColor}80`, showgrid: false, showline: true, visible: false },
          font: { size: 10, color: this.layer.popup.contentColor },
          hovermode: 'x',
          width: 170,
          height: 36,
        }
        if (this.localRange) {
          layout.yaxis.range = this.localRange
        }
        Plotly.react(this.$refs.timeseriesChart, [{ name: this.layer.item, x: this.dates, y: this.values ? this.values : [], type: 'scatter', connectgaps: false, mode: 'lines', line: { color: this.layer.popup.contentColor }, fill: 'tozeroy', hoverinfo: 'none' }], layout, { displayModeBar: false, locale: i18n.locale }).then(() => {
          this.updatePlotTimeIndex()
        })

        this.$refs.timeseriesChart.on('plotly_hover', (data) => {
          var point = data.points[0]
          var xValue = point.x
          var yValue = point.y
          var item = point.data.name
          // this.valueHoverChart = xValue + ' : ' + yValue + ' ,' + item;
          this.valueHoverChart.time = xValue
          let valueHasFormat = this.autoFormatNumbers([yValue], { addComma: true })[0]
          this.valueHoverChart.value = valueHasFormat
          this.valueHoverChart.item = item
        })

        Plotly.react(
          this.$refs.boxplotChart,
          [{ name: this.layer.item, x: this.values, type: 'box', hoverinfo: 'none', line: { color: this.layer.popup.contentColor } }],
          {
            paper_bgcolor: 'transparent',
            plot_bgcolor: 'transparent',
            showlegend: false,
            margin: { t: 0, r: 0, b: 0, l: 0, pad: 0 },
            xaxis: { automargin: true, color: this.layer.popup.contentColor, gridcolor: this.layer.popup.contentColor, showline: true, showgrid: false, visible: this.values && this.values.length ? true : false },
            yaxis: { automargin: true, color: this.layer.popup.contentColor, showticklabels: false, showgrid: false, visible: this.values && this.values.length ? true : false },
            font: { size: 10, color: this.layer.popup.contentColor },
            hovermode: 'y',
            width: 170,
            height: 51,
          },
          { displayModeBar: false, locale: i18n.locale }
        )

        Plotly.react(
          this.$refs.histogramChart,
          [{ name: this.layer.item, x: this.values, type: 'histogram', hoverinfo: 'none', marker: { color: `${this.layer.popup.contentColor}80`, line: { width: 2, color: this.layer.popup.contentColor } } }],
          {
            paper_bgcolor: 'transparent',
            plot_bgcolor: 'transparent',
            showlegend: false,
            margin: { t: 0, r: 0, b: 0, l: 0, pad: 0 },
            xaxis: { automargin: true, color: this.layer.popup.contentColor, gridcolor: this.layer.popup.contentColor, showline: true, showgrid: false, visible: this.values && this.values.length ? true : false },
            yaxis: { automargin: true, color: this.layer.popup.contentColor, showticklabels: false, showgrid: false, visible: this.values && this.values.length ? true : false },
            font: { size: 10, color: this.layer.popup.contentColor },
            width: 170,
            height: 51,
          },
          { displayModeBar: false, locale: i18n.locale }
        )
        this.$refs.histogramChart.on('plotly_hover', (data) => {
          var point = data.points[0]
          var xValue = point.x
          var yValue = point.y
          var item = point.data.name
          // this.valueHoverChart = xValue + ' : ' + yValue;
          this.valueHoverChart.time = yValue
          let valueHasFormat = this.autoFormatNumbers([xValue], { addComma: true })[0]
          this.valueHoverChart.value = valueHasFormat
          this.valueHoverChart.item = item
        })
      } catch {}
    },
    updatePlotTimeIndex() {
      if (this.$refs.timeseriesChart && this.$refs.timeseriesChart.data && this.$refs.timeseriesChart.data.length && this.$refs.timeseriesChart.data[0].x.length) {
        Plotly.relayout(this.$refs.timeseriesChart, {
          shapes: [{ type: 'line', xref: 'x', yref: 'paper', x0: this.date[0], y0: 0, x1: this.date[0], y1: 1, line: { color: this.$store.state.settings.lineColor, width: 2 } }],
        })
      }
    },
    async updatePlotLiveData() {
      this.plotChart()
    },
    deleteImage(id) {
      this.$store.commit('image/DELETE_POPUP_IMAGE', { id })
    },
    async replaceImage() {
      let id = this.idImageReplace
      const imageData = await this.uploadImages('', '', this.$refs.replaceImage.files, 'replace')
      if (imageData) {
        this.$imagesSrc[id] = imageData.src
        this.$store.commit('image/REPLACE_POPUP_IMAGE', { id, fileName: imageData.fileName })
      }
    },
    async uploadImagesEvt() {
      const imageData = await this.uploadImages(this.clicking, this.metadata, this.$refs.image.files)
      if (imageData) {
        this.currentSlide = 0
        this.$store.commit('image/ADD_POPUP_IMAGE', imageData)
      }
    },
    updateMetadata(index, indexLine, value, metadata, imageId, typeFilter) {
      let newMetadata
      if (Array.isArray(value)) {
        if (typeFilter === 'single') {
          value = Array(value[value.length - 1])
        }
        newMetadata = _.cloneDeep(metadata)
        newMetadata[index][indexLine] = value.filter(Boolean).toString()
      } else {
        newMetadata = _.cloneDeep(metadata)
        newMetadata[index][indexLine] = value
      }
      this.$store.commit('image/UPDATE_IMAGE_METADATA', { metadata: newMetadata, imageId: imageId })
    },
    closePopup(layerId, clickId, clickUid, type) {
      const storeData = {
        data: { layerId, clickId, clickUid, type },
        mapIndex: this.mapIndex,
      }
      this.$store.commit('map/CLOSE_POPUP', storeData)
    },

    clearFilter() {
      this.selectedFilter = {}
    },
    showLightBox(id) {
      let aTag = document.querySelector(`#sk-${id}`)
      aTag.click()
    },
  },
  beforeDestroy() {
    Plotly.purge(this.$refs.timeseriesChart)
    Plotly.purge(this.$refs.boxplotChart)
    Plotly.purge(this.$refs.histogramChart)
  },
}
</script>

<style scoped>
.carousel {
  position: unset;
}
.table-metadata.custom-scrollbar::-webkit-scrollbar-thumb {
  background: transparent;
}
.table-metadata:hover.custom-scrollbar::-webkit-scrollbar-thumb {
  background: #888888 !important;
}
.table-metadata thead th {
  padding-right: 10px !important;
}

.carousel.slide:hover .icon-control-image {
  opacity: 1 !important;
}

.icon-control-image {
  transition: 0.2s;
  opacity: 0 !important;
  /* position: absolute;
  right: 0; */
  cursor: pointer;
  padding: 5px;
  border-radius: 50%;
  display: inline-flex;
  justify-content: center;
  align-items: center;
}
.popup-container {
  /* border-radius: 15px; */
  border-radius: 0.428rem;

  width: fit-content;
  /* padding: 10px; */
  /* min-width: 320px; */
  /* max-width: 320px; */
  position: absolute;
  z-index: 401;
  white-space: nowrap;
  box-shadow: 0 3px 6px 1px rgb(0 0 0 / 15%);
}
.popup-container.box-metadata {
  /* min-width: 320px; */
  max-width: 730px;
  min-height: 51px;
}

.table-metadata .table th,
.table-metadata .table td {
  padding: 0px;
}
.table-metadata .table th {
  border-top: none;
}
.table-metadata .table th {
  background: transparent !important;
}

.popup-container .popup-header {
  font-weight: bold;
  text-transform: uppercase;
  user-select: none;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.popup-container .popup-content {
  margin-top: 5px;
  user-select: none;
  display: flex;
}

.popup-container .popup-column {
  width: 50%;
  padding: 0 5px;
}
.popup-container .popup-column.chart {
  width: 60%;
}
.popup-container .popup-column.infomation {
  width: 40%;
}

.popup-container .popup-info {
  display: flex;
  justify-content: space-between;
  line-height: 1;
  margin-bottom: 6px;
}

.popup-container.border .popup-info:last-child {
  margin-bottom: 0;
}

.popup-container .popup-info span:first-child {
  font-weight: bold;
}

.popup-container .popup-range {
  position: relative;
  border-radius: 3px;
  height: 6px;
  margin-top: 10px;
}

.popup-container .popup-range:last-child {
  margin-bottom: 0 !important;
}

.popup-container .popup-range .cursor {
  position: absolute;
  bottom: 0;
  transform: translate(-4px, 6px);
  border-top: 6px solid transparent;
  border-bottom: 6px solid transparent;
  border-left: 4px solid transparent;
  border-right: 4px solid transparent;
}

.popup-container .timeseries-chart {
  margin-top: 5px;
  /* height: 121px; */
}

.popup-container .boxplot-chart {
  margin-top: 5px;
  /* height: 122px; */
}

.popup-container .histogram-chart {
  margin-top: 5px;
  /* height: 122px; */
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s;
}
.fade-enter,
.fade-leave-to {
  opacity: 0;
}
.fade-leave,
.fade-enter-to {
  opacity: 1;
}
.viewport {
  border-top-left-radius: 0px;
  border-top-right-radius: 0px;
  width: 100%;
  height: 218px;
  position: relative;
  overflow: hidden;
}

.popup-container.box-metadata .carousel-item {
  height: 218px;
}
.popup-container.box-metadata .table-metadata {
  overflow-y: auto;
  overflow-x: hidden;
  max-height: 315px;
  text-align: left;
}

.viewport img {
  width: 100%;
  z-index: -1;
  max-height: 100%;
  object-fit: cover;
  position: absolute;
  top: 50%;
  max-width: 100%;
  left: 50%;
  transform: translateY(-50%) translateX(-50%);
}
.popup-container .upload-image:hover {
  background-color: rgb(255 255 255 / 12%);
}
</style>

<style lang="scss">
.popup-container.box-metadata .carousel-control-prev,
.carousel-control-next {
  height: 100% !important;
  top: 0px !important;
}
/* .box-metadata .radio-container {
  border: 1px solid #ffffff;
} */

.box-metadata .radio-container span {
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-line-clamp: 4;
  -webkit-box-orient: vertical;
  margin: 0px;
  white-space: nowrap;
  font-weight: 600;
}
.box-metadata .radio-container {
  border: 0px;
}

.box-metadata .radio-container label {
  padding: 0px;
  text-align: center;
  white-space: normal !important;
  width: 51px !important;
}

.popup-container .carousel-control-prev,
.carousel-control-next {
  top: 25px;
  bottom: 25px;
}
.box-metadata .carousel-indicators {
  display: none;
}

.popup-container .carousel-indicators li:focus {
  outline: none;
}
.popup-container .carousel-indicators li {
  width: 5px;
  height: 5px;
  border-top: unset;
  border-bottom: unset;
  border-radius: 50%;
}
.popup-container .carousel-caption {
  user-select: none;
  padding: 0;
  text-align: right;
  top: 0;
  right: 0;
  bottom: auto;
  left: auto;
}
.popup-container .timeseries-chart .axistext {
  display: none;
}
.gcontainer button {
  outline: none;
}
.option-filter {
  width: 51px;
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-line-clamp: 4;
  -webkit-box-orient: vertical;
  margin: 0px;
  white-space: nowrap;
  font-weight: 600;
  text-align: center;
}
.box-filter {
  margin-right: 5px;
  align-self: center;
  height: 100%;
  border-radius: 5px;
  padding-top: 6px;
}
.popup-container .fadein-down .animate-content.show {
  top: 30px;
}
.popup-container .dropdown-menu {
  padding: 0.5rem;
}
.select-natura .vs__actions {
  display: none;
}
.select-natura .vs__dropdown-toggle {
  padding: 0px !important;
}
.carousel-indicators {
  bottom: -13px;
}
.carousel-control-next-icon,
.carousel-control-prev-icon {
  filter: brightness(0);
}
.glightbox-clean .gslide-title {
  font-weight: 700;
}
.select-one .custom-control-label::before,
.select-one .popup-select-metadata .custom-control-label::after {
  border-radius: 20px !important;
}
.ginner-container.desc-right {
  min-height: 500px !important;
}
.table-metadata td div {
  padding: 0px;
}
.popup-value-time {
  margin-bottom: 22px;
  .popup-info {
    margin-bottom: 0 !important;
  }

  .popup-value-time-item {
    position: relative;
    border-radius: 3px;
    height: 6px;
    margin-top: 5px !important;
    margin-bottom: 12px !important;
    .cursor {
      position: absolute;
      bottom: 0;
      transform: translate(-4px, 6px);
      border-top: 6px solid transparent;
      border-bottom: 6px solid transparent;
      border-left: 4px solid transparent;
      border-right: 4px solid transparent;
    }
  }
}
.timeseries-chart-tick-date {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 11px;
  height: 30px;
  &-start {
    align-items: flex-start;
  }
  &-end {
    align-items: flex-end;
  }
}
.information-chart-pp {
  width: 40% !important;
}
</style>
