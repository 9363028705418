<template>
  <div class="popup-container box-metadata" v-show="visibleLayer" :style="{ top: position.y + 'px', left: position.x + 'px', fontFamily: layer.popup.family }">
    <svg style="max-width: 535px" class="popup-svg" height="30" width="100%">
      <polyline points="4,26 174,26 200,1 999,1" :style="{ fill: 'none', strokeWidth: 2, stroke: layer.popup.lineColor }" />
      <circle cx="4" cy="26" r="4" :fill="layer.popup.lineColor" />
    </svg>
    <div class="popup-text">
      <div class="popup-header" :style="{ fontSize: layer.popup.headerSize + 'px', color: layer.popup.headerColorBorderless }">
        <div>
          <!-- {{ layer && layer.dataTimeseries ? location.feature.properties[idMultiSelected] : layer && !layer.dataTimeseries && layer.itemMultiSelected && clicking && clicking.itemMultiSelected ? clicking.itemMultiSelected : '' }} - {{ layer.name }} -->
          {{ nameItem }}
        </div>
        <div>
          <feather-icon size="16" icon="ImageIcon" class="cursor-pointer" :title="$t('add_image')" @click="toggleImage" />
          <feather-icon v-if="layer.popup.multiShow" size="16" icon="XIcon" class="cursor-pointer" @click="closePopup(clicking.layerId, clicking.clickId, clicking.clickUid, layer.type)" />
        </div>
      </div>
      <div class="d-flex">
        <div style="margin-right: 10px" v-show="(popupImage && popupImage.length !== 0 && currentValue !== null) || (!popupImage && currentValue !== null) || (popupImage && popupImage.length === 0 && currentValue !== null) || (popupImage && popupImage.length === 0 && !flagFilter && currentValue !== null)">
          <div class="popup-content" v-if="!isOutside" :style="{ fontSize: layer.popup.contentSize + 'px', color: layer.popup.contentColorBorderless }">
            <div class="popup-column">
              <div class="popup-info">
                <span>{{ $t('value_popup') }}</span>
                <span>{{ currentValueFormat }}</span>
              </div>
              <div class="popup-range" :style="{ backgroundColor: `${layer.popup.contentColorBorderless}80`, backgroundImage: `linear-gradient(to right, ${layer.popup.contentColorBorderless} 0%, ${layer.popup.contentColorBorderless} ${valuePercent}%, transparent ${valuePercent}%, transparent 100%)`, marginBottom: `${4 * layer.popup.contentSize - 18 - 2}px` }">
                <div class="cursor" :style="{ borderBottomColor: layer.popup.contentColorBorderless, left: `${valuePercent}%` }"></div>
              </div>
              <div class="popup-info">
                <span>{{ $t('time_popup') }}</span>
                <span>{{ currentTime }}</span>
              </div>
              <div class="popup-range" :style="{ backgroundColor: `${layer.popup.contentColorBorderless}80`, backgroundImage: `linear-gradient(to right, ${layer.popup.contentColorBorderless} 0%, ${layer.popup.contentColorBorderless} ${timePercent}%, transparent ${timePercent}%, transparent 100%)` }">
                <div class="cursor" :style="{ borderBottomColor: layer.popup.contentColorBorderless, left: `${timePercent}%` }"></div>
              </div>
            </div>
            <div class="popup-column">
              <div class="popup-info">
                <span>{{ $t('min') }}</span>
                <span>{{ statistic.min }}</span>
              </div>
              <div class="popup-info">
                <span>Q1</span>
                <span>{{ statistic.first }}</span>
              </div>
              <div class="popup-info">
                <span>{{ $t('median') }}</span>
                <span>{{ statistic.median }}</span>
              </div>
              <div class="popup-info">
                <span>{{ $t('mean') }}</span>
                <span>{{ statistic.mean }}</span>
              </div>
              <div class="popup-info">
                <span>Q3</span>
                <span>{{ statistic.third }}</span>
              </div>
              <div class="popup-info">
                <span>{{ $t('max') }}</span>
                <span>{{ statistic.max }}</span>
              </div>
            </div>
          </div>
          <div ref="timeseriesChart" v-show="showTimeseriesChart" class="timeseries-chart"></div>
          <div ref="boxplotChart" v-show="showTimeseriesChart" class="boxplot-chart"></div>
          <div ref="histogramChart" v-show="showTimeseriesChart" class="histogram-chart"></div>
        </div>
        <div v-if="popupImage && popupImage.length === 0 && flagFilter" style="padding-top: 170px; margin-right: 10px" class="popup-image" :style="{ fontSize: layer.popup.headerSize + 'px', color: layer.popup.headerColorBorderless }">
          <div>{{ $t('empty_image') }}</div>
          <div class="mt-1" style="text-align: center">
            <b-button variant="primary" @click="clearFilter">{{ $t('clear_filter') }}</b-button>
          </div>
        </div>
        <div v-if="popupImage && popupImage.length !== 0" style="margin-right: 10px" class="custom-scrollbar popup-image">
          <a :id="`sk-${data.id}`" :href="getImageSourceById(data.id)" v-for="data in popupImage" :key="data.id" :data-description="showDesMetadata(data.metadata)" class="glightbox" data-desc-position="right" data-type="image" data-effect="fade" data-zoomable="true" data-draggable="true" data-dragToleranceY="0"> </a>
          <VirtualList class="chart-container custom-scrollbar image-container" ref="imageList" :data-key="'id'" :data-sources="popupImage" :data-component="imageComponent" :keeps="15" />
        </div>
        <div v-if="showMetadata && dataFilterShow.length && flagFilter && popupImage.length !== 0" class="custom-scrollbar" style="overflow: unset !important; width: 360px">
          <div v-if="dataFilter.length !== 0" class="d-flex" style="margin: 6px 0px 8px 0px; width: 350px; height: 30px">
            <div v-for="(filter, index) in dataFilterShow" :key="index">
              <FilterMetadata @getDatafilter="getDataFilter" :value="selectedFilter" :options="filter" :name="filter.text" :mode="'borderless'" />
            </div>
          </div>
          <div class="table-metadata" :style="{ fontSize: layer.popup.contentSize + 'px', color: layer.popup.contentColorBorderless }">
            <table class="table b-table" :style="{ fontSize: layer.popup.contentSize + 'px', color: layer.popup.contentColorBorderless, background: 'transparent' }">
              <thead :style="{ fontSize: layer.popup.contentSize + 'px', color: layer.popup.contentColorBorderless, 'border-color': layer.popup.contentColorBorderless + '!important' }">
                <tr role="row" class="">
                  <th style="border: none !important" v-for="(title, index) in showMetadata[0]" :key="index" :style="{ fontSize: layer.popup.contentSize + 'px', color: layer.popup.contentColorBorderless, 'border-color': layer.popup.contentColorBorderless + '!important' }">
                    <div v-if="title.toUpperCase() === 'CLASSIFICATION'">{{ $t('classification') }}</div>
                    <div v-else-if="title.toUpperCase() === 'METADATA'">{{ $t('metadata') }}</div>
                    <div v-else-if="title.toUpperCase() === 'VALUE'">{{ $t('value') }}</div>
                    <div v-else>{{ title }}</div>
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(line, index) in showMetadata" :key="index">
                  <td style="vertical-align: top; line-height: normal" v-for="(title, indexLine) in line" :key="indexLine" :style="{ display: index === 0 ? 'none' : 'table-cell', 'border-top': indexLine < 1 && title === '' ? 'none !important' : '1px solid', fontSize: layer.popup.contentSize + 'px', color: layer.popup.contentColorBorderless, 'border-color': layer.popup.contentColorBorderless + '!important' }">
                    <div v-if="indexLine < 2">{{ title }}</div>
                    <div v-else-if="dataSelectMetadata[line[1]] && dataSelectMetadata[line[1]].option.length !== 0">
                      <SelectMetadataComponent :class="{ 'select-one': dataSelectMetadata[line[1]].typeFilter === 'single' }" :mode="'borderless'" @selectValue="(valueSelect) => updateMetadata(index, indexLine, valueSelect, showMetadata, indexImageMetadata, imageIdSelected, dataSelectMetadata[line[1]].typeFilter)" :value="title.replaceAll('、', ',').split(',')" :options="dataSelectMetadata[line[1]].option" />
                    </div>

                    <div v-else class="d-flex">
                      <b-form-input @change="updateMetadata(index, indexLine, $event, showMetadata, indexImageMetadata, imageIdSelected)" style="padding: 0px; height: 18px; background: transparent; border: 0px; box-shadow: none" :style="{ fontSize: layer.popup.contentSize + 'px', color: layer.popup.contentColorBorderless }" :value="title" />
                      <feather-icon v-if="line[1] === '測定日時'" size="16" icon="CalendarIcon" style="align-self: center" :style="{ fontSize: layer.popup.contentSize + 'px', color: layer.popup.contentColorBorderless }" />
                      <feather-icon v-if="line[1] === '緯度経度'" size="16" icon="MapPinIcon" style="align-self: center" :style="{ fontSize: layer.popup.contentSize + 'px', color: layer.popup.contentColorBorderless }" />
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
    <input type="file" ref="image" multiple style="display: none" @change="uploadImagesEvt" accept=".png, .jpeg, .jpg, .zip" />
  </div>
</template>

<script>
const _ = require('lodash')
const dayjs = require('dayjs')
const relativeTime = require('dayjs/plugin/relativeTime')
import { METADATA_POPUP } from '@/constants/metadata.js'
import VirtualList from 'vue-virtual-scroll-list'
import Plotly from 'plotly.js-dist'
import GLightbox from 'glightbox'
import RadioGroup from '../../../common/RadioGroup.vue'
import i18n from '@/libs/i18n'
import MathMixin from '@/mixins/MathMixin'
import DynamicAnimate from '../../../common/DynamicAnimate.vue'
import SelectMetadataComponent from './SelectMetadataComponent.vue'
import FilterMetadata from './FilterMetadataComponent.vue'
import ImageMixin from '@/mixins/ImageMixin'
import BorderlessImageComponent from './BorderlessImageComponent.vue'
import 'glightbox/dist/css/glightbox.css'
import { ThemeConfig } from '@/mixins/ThemeMixin.js'
dayjs.extend(relativeTime)

export default {
  props: ['map', 'moveCount', 'layer', 'leafletLayer', 'clicking', 'mapIndex'],
  mixins: [MathMixin, ThemeConfig, ImageMixin],
  components: { RadioGroup, DynamicAnimate, SelectMetadataComponent, FilterMetadata, VirtualList },
  data() {
    return {
      imageComponent: BorderlessImageComponent,
      filterNatural: [],
      filterFuntion: [],
      filterPlance: [],
      filterProcess: [],
      filterState: [],
      filterMeasurement: [],
      selectedFilter: {},
      optionNatural: [],
      optionFuntion: [],
      optionPlance: [],
      optionProcess: [],
      optionState: [],
      optionMeasurement: [],
      flagFilter: false,
      dates: [],
      values: [],
      showMetadata: false,
      indexImageMetadata: null,
      metadata: METADATA_POPUP,
      imageIdSelected: null,
      listFilter: null,
      showTimeseriesChart: true,
      showBoxplotChart: true,
      showHistogramChart: true,
    }
  },
  mounted() {
    this.createLightbox()
  },
  computed: {
    nameItem() {
      //   return this.layer.type !== 'multi' || this.layer.dataTimeseries ? this.location.feature.properties[this.idMultiSelected] : this.hovering && this.hovering.idMultiSelected ? this.hovering.idMultiSelected : null;
      let item = ``
      try {
        item = this.location.feature.properties[this.idMultiSelected]
      } catch {}
      try {
        if (!item || item == '') {
          item = this.clicking.itemMultiSelected
        }
      } catch {}
      return `${item} - ${this.layer.name}`
    },
    isOutside() {
      let isOutside = true
      try {
        if (!this.layer.dataTimeseries) {
          return true
        }
        const dataTimeseries = this.$db[this.layer.dataTimeseries]
        if (dataTimeseries.dates) this.dates = dataTimeseries.dates
        if (dataTimeseries && dataTimeseries.dates && dataTimeseries.dates.length) {
          isOutside = !(dataTimeseries.dates[0] <= this.date[0] && this.date[0] <= dataTimeseries.dates[dataTimeseries.dates.length - 1])
        }
      } catch {}
      if (isOutside) {
        this.showBoxplotChart = false
        this.showHistogramChart = false
        this.showTimeseriesChart = false
      } else {
        this.showBoxplotChart = true
        this.showHistogramChart = true
        this.showTimeseriesChart = true
      }
      return isOutside
    },
    locale() {
      return i18n.locale
    },
    date() {
      return this.$store.state.map.date
    },
    location() {
      return this.leafletLayer.getLayers().find((l) => {
        try {
          if (l.feature.properties[this.idMultiSelected]) {
            if (this.layer && this.layer.type && this.layer.type == 'point') {
              return l.feature.properties[this.idMultiSelected] == this.clicking.clickId
            } else {
              if (this.clicking) {
                if (this.clicking.clickUid && this.clicking.latlng) {
                  return l.feature.properties[this.idMultiSelected] == this.clicking.clickId && l.feature.properties.UID == this.clicking.clickUid && JSON.stringify(l.latlng) == JSON.stringify(this.clicking.latlng)
                } else if (!this.clicking.clickUid && this.clicking.latlng) {
                  return l.feature.properties[this.idMultiSelected] == this.clicking.clickId && JSON.stringify(l.latlng) == JSON.stringify(this.clicking.latlng)
                } else if (this.clicking.clickUid && !this.clicking.latlng) {
                  return l.feature.properties[this.idMultiSelected] == this.clicking.clickId && l.feature.properties.UID == this.clicking.clickUid
                } else {
                  return l.feature.properties[this.idMultiSelected] == this.clicking.clickId
                }
              }
            }
          } else if (l.noneId && ((this.clicking.clickId && `${l.noneId}` == `${this.clicking.clickId}`) || (!this.clicking.clickId && this.clicking.layerId && this.clicking.latlng && `${l.noneId}` == `${this.clicking.layerId}-${this.clicking.latlng[0]},${this.clicking.latlng[1]}`))) {
            return true
          }
        } catch {}
        return false
      })
    },
    layerOrigin() {
      try {
        const layers = this.$store.state.layer.layers
        if (layers) {
          for (let i = 0; i < layers.length; i++) {
            if (layers[i].id == this.clicking.layerId) {
              return layers[i]
            }
          }
        }
      } catch {}
      return
    },
    position() {
      const self = this
      this.moveCount
      try {
        if (this.layerOrigin) {
          if (this.layerOrigin.type == 'point') {
            let useMarkerCluster
            try {
              useMarkerCluster = this.layerOrigin && this.layerOrigin.markerCluster && this.layerOrigin.markerCluster.using
            } catch {}
            if (this.clicking.source && useMarkerCluster) {
              if (!this.clicking.position || (this.clicking.position && !this.clicking.position.x && !this.clicking.position.y && !this.clicking.position.top && !this.clicking.position.left)) {
                this.moveCount
                let allCluster
                allCluster = this.leafletLayer._parentGroup._featureGroup._layers
                if (allCluster && Object.keys(allCluster).length) {
                  for (let key in allCluster) {
                    try {
                      if (allCluster[key]._childCount) {
                        let allclusterChild = allCluster[key].getAllChildMarkers()
                        for (let k = 0; k < allclusterChild.length; k++) {
                          if (allclusterChild[k].feature.properties[this.idMultiSelected] == self.clicking.clickId) {
                            if (allCluster[key].isSpiderfied) {
                              return self.map.latLngToContainerPoint(allclusterChild[k].getLatLng())
                            } else {
                              return self.map.latLngToContainerPoint(allCluster[key].getLatLng())
                            }
                            break
                          }
                        }
                      } else {
                        if (allCluster[key].feature.properties[this.idMultiSelected] == self.clicking.clickId) {
                          return self.map.latLngToContainerPoint(allCluster[key].getLatLng())
                        }
                      }
                    } catch {}
                  }
                }
              }
            }
            if (this.clicking.position && this.clicking.position.top) {
              return {
                y: this.clicking.position.top,
                x: this.clicking.position.left,
              }
            }
            return this.map.latLngToContainerPoint(this.location.latlng)
          } else {
            if (this.clicking.position && this.clicking.position.top) {
              return {
                y: this.clicking.position.top,
                x: this.clicking.position.left,
              }
            }
            return this.map.latLngToContainerPoint(this.location.latlng)
          }
        }
      } catch {}
      return {
        y: 0,
        x: 0,
      }
    },
    popupImages() {
      let popupImagesTemp = this.$store.state.image.popupImages
      let data = []
      try {
        if (this.layer.type == 'point') {
          data = this.$store.state.image.popupImages.filter((data) => data.layerId === this.clicking.layerId && data.locationItem === this.clicking.clickId)
        } else {
          data = this.$store.state.image.popupImages.filter((data) => data.layerId === this.clicking.layerId && data.locationItem === this.clicking.clickId && (this.clicking.latlng && data.latlngPos ? JSON.stringify(data.latlngPos) === JSON.stringify(this.clicking.latlng) : true))
        }
      } catch {}
      return data
    },
    listChangeListPopup() {
      return this.$store.state.image.popupImages
    },
    totalImagePopup() {
      return this.popupImages.length
    },
    popupImage() {
      this.flagFilter = false
      const imageData = this.filterImageMetadata(this.popupImages, this.selectedFilter)
      if (this.popupImages && this.popupImages.length !== 0) this.flagFilter = true
      else this.flagFilter = false
      this.createLightbox()
      return imageData
    },
    currentValue() {
      if (!this.values || !this.dates.length || this.date[0] < this.dates[0] || this.date[0] > this.dates[this.dates.length - 1]) return null
      let snapIndex = this.dates.reduce((res, date, index) => (date <= this.date[0] ? index : res), -1)
      return this.values[snapIndex]
    },
    currentValueFormat() {
      try {
        return this.autoFormatNumbers([this.currentValue], { addComma: true })[0]
      } catch {}
      return this.currentValue
    },
    valuePercent() {
      if (this.currentValue === null) return 0
      return ((this.currentValue - this.statistic.minDefault) / (this.statistic.maxDefault - this.statistic.minDefault)) * 100
    },
    calendarUnit() {
      return this.$store.state.settings.calendarUnit
    },
    currentTime() {
      const diffUnit = { yearly: 'years', monthly: 'months', daily: 'days', hourly: 'hours' }
      const diffUnitName = { yearly: this.$i18n.t('years'), monthly: this.$i18n.t('months'), daily: this.$i18n.t('days'), hourly: this.$i18n.t('hours') }
      let unitTime = null
      if (!this.dates.length || this.date[0] <= this.dates[0]) return null
      // return dayjs(this.date[0]).from(dayjs(this.dates[0]), true);

      let start = dayjs(this.dates[0])
      let end = dayjs(this.date[0])

      if (this.calendarUnit === 'auto') {
        for (const key in diffUnit) {
          let diff = end.diff(start, diffUnit[key])
          if (diff > 0 || key === 'hourly') {
            unitTime = diffUnitName[key]
            return diff + ' ' + unitTime
          }
        }
      }

      unitTime = diffUnitName[this.calendarUnit]
      return end.diff(start, diffUnit[this.calendarUnit])
    },
    timePercent() {
      if (!this.dates.length) return 0
      let min = new Date(this.dates[0]).getTime()
      let max = new Date(this.dates[this.dates.length - 1]).getTime()
      let current = new Date(this.date[0]).getTime()
      if (current < min) return 0
      if (current > max) return 100
      return ((current - min) / (max - min)) * 100
    },
    statistic() {
      let filtered = this.values ? this.values.filter((v) => v !== null) : []
      if (!filtered.length) return { min: 0, max: 0, mean: 0, median: 0, first: 0, third: 0 }
      let sorted = this.sortData(filtered)
      let minDefault = this.getMin(sorted)
      let maxDefault = this.getMax(sorted)
      let rounded = this.autoFormatNumbers([minDefault, maxDefault, this.getMean(sorted), this.getMedian(sorted), this.getFirstQuantile(sorted), this.getThirdQuantile(sorted)], { addComma: true })
      return { min: rounded[0], max: rounded[1], mean: rounded[2], median: rounded[3], first: rounded[4], third: rounded[5], minDefault, maxDefault }
    },
    itemChanged() {
      return this.layer.dataTimeseries + '_' + this.layer.item + '_' + this.clicking.clickId + '_' + this.layer.scale.value
    },
    liveMode() {
      if (!this.layer) return false
      let datasource = this.datasources.find((d) => d.id === this.layer.dataTimeseries)
      if (!datasource) return false
      return datasource.live
    },
    // refreshTime() {
    //   return this.$store.state.map.refreshTime;
    // },
    countLiveMode() {
      return this.$store.state.settings.countLiveMode
    },
    dataFilter() {
      return this.$store.state.settings.dataFilter
    },
    dataFilterShow() {
      try {
        this.selectedFilter = {}
        if (this.dataFilter && this.dataFilter[0].children.length !== 0) {
          let arrayObjFilter = this.dataFilter[0].children.filter((obj) => obj.visible === 'show')
          return arrayObjFilter
        }
      } catch {}
      return []
    },
    dataSelectMetadata() {
      let arrayShow = []
      let objSelect = {}
      if (this.dataFilter && this.dataFilter[0].children.length !== 0) {
        arrayShow = this.dataFilter[0].children.filter((obj) => obj.visible === 'show')
        for (let i = 0; i < arrayShow.length; i++) {
          let typeFilter = ''
          let result = []
          if (arrayShow[i] && arrayShow[i].children.length !== 0) {
            result = arrayShow[i].children
            typeFilter = arrayShow[i].typeFilter
          }
          objSelect[arrayShow[i].text] = { key: arrayShow[i].text, typeFilter: typeFilter, option: result }
        }
      }
      return objSelect
    },
    visibleLayer() {
      return this.layer.visible[this.mapIndex]
    },
    idMultiSelected() {
      let id = 'ID'
      try {
        id = this.layer.idMultiSelected ? this.layer.idMultiSelected : 'ID'
      } catch {}
      return id
    },
    localRange() {
      try {
        if (this.statistic && this.statistic.minDefault != null && this.statistic.maxDefault != null) {
          return [Number(this.statistic.minDefault), Number(this.statistic.maxDefault)]
        } else {
          if (!this.values || !this.values.length) {
            return
          } else {
            let sourceFilerNA = this.values.filter((bt) => !this.isNaValue(bt))
            let min = Math.min(...sourceFilerNA)
            let max = Math.max(...sourceFilerNA)
            return [Number(min), Number(max)]
          }
        }
      } catch {}
      return
    },
  },
  watch: {
    isOutside(isOutside) {
      if (isOutside) {
        this.showBoxplotChart = false
        this.showHistogramChart = false
        this.showTimeseriesChart = false
      } else {
        this.showBoxplotChart = true
        this.showHistogramChart = true
        this.showTimeseriesChart = true
      }
    },
    dataFilter() {
      this.listFilter = this.getlistCountFilter(this.popupImages)
    },
    listChangeListPopup: {
      deep: true,
      handler() {
        this.listFilter = this.getlistCountFilter(this.popupImages)
      },
    },
    locale() {
      this.updatePlotLiveData()
    },
    itemChanged: {
      immediate: true,
      handler() {
        this.plotChart()
      },
    },
    date: {
      immediate: true,
      handler() {
        this.updatePlotTimeIndex()
      },
    },
    // refreshTime() {
    //   this.updatePlotLiveData();
    // },
    countLiveMode() {
      this.updatePlotLiveData()
    },
  },
  methods: {
    toggleImage() {
      this.$refs.image.click()
    },
    toggleReplaceImage(id) {
      this.$refs.replaceImage.click(id)
    },
    getDataFilter() {
      let obj = _.cloneDeep(this.selectedFilter)
      this.selectedFilter = {}
      this.selectedFilter = obj
    },
    clearFilter() {
      this.selectedFilter = {}
    },
    updateMetadata(index, indexLine, value, metadata, indexImage, imageId, typeFilter) {
      let newMetadata
      if (Array.isArray(value)) {
        if (typeFilter === 'single') {
          value = Array(value[value.length - 1])
        }
        newMetadata = _.cloneDeep(metadata)
        newMetadata[index][indexLine] = value.filter(Boolean).toString()
      } else {
        newMetadata = _.cloneDeep(metadata)
        newMetadata[index][indexLine] = value
      }
      this.$store.commit('image/UPDATE_IMAGE_METADATA', { metadata: newMetadata, imageId: imageId })

      this.showMetadata = _.cloneDeep(newMetadata)
      this.indexImageMetadata = indexImage
    },

    createLightbox() {
      setTimeout(() => {
        if (this.lightbox) {
          this.lightbox.destroy()
          this.lightbox = null
        }
        this.lightbox = GLightbox({
          dragToleranceY: 0,
        })
      }, 0)
    },
    async plotChart(func) {
      this.listFilter = this.getlistCountFilter(this.popupImages)
      if (!this.layer.dataTimeseries || !this.layer.item) return
      // Query dates
      let dataTimeseries = this.$db[this.layer.dataTimeseries]
      if (dataTimeseries) this.dates = dataTimeseries.dates
      // Query values
      let locationItem = [this.location.feature.properties[this.idMultiSelected] + '-' + this.layer.item]
      let data = await this.selectAllByLocationsItems(this.layer.dataTimeseries, locationItem, this.layer.scale.value)

      this.values = data[locationItem]
      let layout = {
        paper_bgcolor: 'transparent',
        plot_bgcolor: 'transparent',
        showlegend: false,
        margin: { t: 0, r: 0, b: 0, l: 0, pad: 0 },
        xaxis: { automargin: true, color: this.layer.popup.contentColorBorderless, gridcolor: `${this.layer.popup.contentColorBorderless}80`, showgrid: false },
        yaxis: { automargin: true, color: this.layer.popup.contentColorBorderless, gridcolor: `${this.layer.popup.contentColorBorderless}80`, showline: true },
        font: { size: 10, color: this.layer.popup.contentColorBorderless },
        hovermode: 'x',
        width: 310,
        height: 110,
      }
      if (this.localRange) {
        layout.yaxis.range = this.localRange
      }
      Plotly.react(this.$refs.timeseriesChart, [{ name: this.layer.item, x: this.dates, y: this.values, type: 'scatter', connectgaps: false, mode: 'lines', line: { color: this.layer.popup.contentColorBorderless }, fill: 'tozeroy', hovertemplate: '(%{x|%Y-%m-%d %H:%M:%S}: %{y},%{fullData.name})<extra></extra>' }], layout, { displayModeBar: false, locale: i18n.locale }).then(() => {
        this.updatePlotTimeIndex()
      })

      Plotly.react(
        this.$refs.boxplotChart,
        [{ name: this.layer.item, x: this.values, type: 'box', hoverinfo: 'none', line: { color: this.layer.popup.contentColorBorderless } }],
        {
          paper_bgcolor: 'transparent',
          plot_bgcolor: 'transparent',
          showlegend: false,
          margin: { t: 0, r: 0, b: 0, l: 0, pad: 0 },
          xaxis: { automargin: true, color: this.layer.popup.contentColorBorderless, gridcolor: this.layer.popup.contentColorBorderless, showline: true, showgrid: false },
          yaxis: { automargin: true, color: this.layer.popup.contentColorBorderless, showticklabels: false, showgrid: false },
          font: { size: 10, color: this.layer.popup.contentColorBorderless },
          hovermode: 'y',
          width: 310,
          height: 110,
        },
        { displayModeBar: false, locale: i18n.locale }
      )

      Plotly.react(
        this.$refs.histogramChart,
        [{ name: this.layer.item, x: this.values, type: 'histogram', marker: { color: `${this.layer.popup.contentColorBorderless}80`, line: { width: 2, color: this.layer.popup.contentColorBorderless } } }],
        {
          paper_bgcolor: 'transparent',
          plot_bgcolor: 'transparent',
          showlegend: false,
          margin: { t: 0, r: 0, b: 0, l: 0, pad: 0 },
          xaxis: { automargin: true, color: this.layer.popup.contentColorBorderless, gridcolor: this.layer.popup.contentColorBorderless, showline: true, showgrid: false },
          yaxis: { automargin: true, color: this.layer.popup.contentColorBorderless, showticklabels: false, showgrid: false },
          font: { size: 10, color: this.layer.popup.contentColorBorderless },
          width: 310,
          height: 110,
        },
        { displayModeBar: false, locale: i18n.locale }
      )
    },
    updatePlotTimeIndex() {
      if (this.$refs.timeseriesChart && this.$refs.timeseriesChart.data.length && this.$refs.timeseriesChart.data[0].x.length) {
        Plotly.relayout(this.$refs.timeseriesChart, {
          shapes: [{ type: 'line', xref: 'x', yref: 'paper', x0: this.date[0], y0: 0, x1: this.date[0], y1: 1, line: { color: this.$store.state.settings.lineColor, width: 2 } }],
        })
      }
    },
    async updatePlotLiveData() {
      // Append Live data
      const locationItem = [this.location.feature.properties[this.idMultiSelected] + '-' + this.layer.item]
      const liveData = await this.selectAllByLocationsItems(this.layer.dataTimeseries, locationItem, 'live')
      const values = this.values.concat(liveData[locationItem])
      const dates = this.dates.concat(liveData['dates'])
      Plotly.react(
        this.$refs.timeseriesChart,
        [{ name: this.layer.item, x: dates, y: values, type: 'scatter', connectgaps: false, mode: 'lines', line: { color: this.layer.popup.contentColorBorderless }, fill: 'tozeroy', hovertemplate: '(%{x|%Y-%m-%d %H:%M:%S}: %{y}, %{fullData.name})<extra></extra>' }],
        {
          paper_bgcolor: 'transparent',
          plot_bgcolor: 'transparent',
          showlegend: false,
          margin: { t: 0, r: 0, b: 0, l: 0, pad: 0 },
          xaxis: { automargin: true, color: this.layer.popup.contentColorBorderless, gridcolor: `${this.layer.popup.contentColorBorderless}80`, showgrid: false },
          yaxis: { automargin: true, color: this.layer.popup.contentColorBorderless, gridcolor: `${this.layer.popup.contentColorBorderless}80`, showline: true },
          font: { size: 10, color: this.layer.popup.contentColorBorderless },
          hovermode: 'x',
          width: 310,
          height: 110,
        },
        { displayModeBar: false, locale: i18n.locale }
      ).then(() => {
        this.updatePlotTimeIndex()
      })
    },
    closePopup(layerId, clickId, clickUid, type) {
      const storeData = {
        data: { layerId, clickId, clickUid, type },
        mapIndex: this.mapIndex,
      }
      this.$store.commit('map/CLOSE_POPUP', storeData)
    },
    async uploadImagesEvt() {
      const imageData = await this.uploadImages(this.clicking, this.metadata, this.$refs.image.files)
      if (imageData) {
        this.currentSlide = 0
        this.$store.commit('image/ADD_POPUP_IMAGE', imageData)
      }
    },
  },
  beforeDestroy() {
    try {
      if (this.$refs.timeseriesChart) Plotly.purge(this.$refs.timeseriesChart)
      if (this.$refs.boxplotChart) Plotly.purge(this.$refs.boxplotChart)
      if (this.$refs.boxplotChart) Plotly.purge(this.$refs.boxplotChart)
    } catch {}
  },
}
</script>

<style scoped>
.popup-container .chart-container {
  position: relative;
  height: 481px;
  overflow-y: scroll;
  overflow-x: hidden;
}
.table-metadata .table th,
.table-metadata .table td {
  padding: 1px 5px;
}
.table-metadata .table th {
  background: transparent !important;
}

.popup-container {
  position: absolute;
  z-index: 401;
  transform: translate(-4px, -56px);
  white-space: nowrap;
  width: fit-content;
  pointer-events: none;
}

.popup-container .popup-svg {
  position: absolute;
  top: 30px;
  z-index: -1;
  padding-right: 10px;
}

.popup-container .popup-text {
  margin-left: 200px;
  padding: 0 10px;
  width: auto;
  pointer-events: auto;
}

.popup-container .popup-header {
  /* padding-right: 4px; */
  font-weight: bold;
  margin-bottom: 10px;
  height: 30px;
  line-height: 30px;
  text-transform: uppercase;
  display: flex;
  justify-content: space-between;
  align-items: center;
  min-width: 310px;
  max-width: 310px;
}

.popup-container .popup-content {
  user-select: none;
  display: flex;
}

.popup-container .popup-column {
  width: 50%;
  padding: 0px 0px 0px 5px;
}

.popup-container .popup-info {
  display: flex;
  justify-content: space-between;
  line-height: 1;
  margin-bottom: 4px;
}

.popup-container.border .popup-info:last-child {
  margin-bottom: 0;
}

.popup-container .popup-info span:first-child {
  font-weight: bold;
}

.popup-container .popup-range {
  position: relative;
  border-radius: 3px;
  height: 6px;
  margin-top: 10px;
}

.popup-container .popup-range:last-child {
  margin-bottom: 0 !important;
}

.popup-container .popup-range .cursor {
  position: absolute;
  bottom: 0;
  transform: translate(-4px, 6px);
  border-top: 6px solid transparent;
  border-bottom: 6px solid transparent;
  border-left: 4px solid transparent;
  border-right: 4px solid transparent;
}

.popup-container .timeseries-chart {
  min-width: 310px;
  margin-top: 10px;
  height: 110px;
}

.popup-container .boxplot-chart {
  margin-top: 10px;
  height: 110px;
  min-width: 310px;
}

.popup-container .histogram-chart {
  margin-top: 10px;
  height: 110px;
  min-width: 310px;
}

.popup-container .popup-image {
  /* position: absolute;
  top: 0;
  left: 100%; */
  max-height: 100%;
  /* padding-left: 10px; */
  pointer-events: auto;
  overflow-y: auto;
  max-height: 481px !important;
  width: 330px;
  position: relative;
}

.popup-metadata {
  transition: 0.5s;
  position: absolute;
  top: 0;
  left: 162%;
  max-height: 100%;
  padding-left: 10px;
  pointer-events: auto;
  overflow-y: auto;
  max-height: 481px !important;
  width: 360px;
}

-upload {
  width: 100%;
  height: 100%;
}
.icon-control-image {
  cursor: pointer;
  position: absolute;
  right: 5px;
}
.icon-control-image.upload-image {
  right: 30px;
}

.icon-control-image.full-screen-image {
  right: 55px;
}
</style>

<style>
.popup-container .timeseries-chart .axistext {
  display: none;
}
</style>
